import { Typography } from '@fox/atoms';
import { FlexColumnSpaced } from '@fox/layout';
import React from 'react';
import { useComponentClassContext } from '../component-class-context';
import { useComponentStyleContext } from '../component-style-context';
import { IRenderSearchResultsProps, SiteSearchResultModel } from './contracts';

export const RenderSearchResults = (props: IRenderSearchResultsProps) => {
	const { results } = props;
	const { font, headerTextShadow, subtextFont, subtextFontColour } = useComponentStyleContext();
	const { headerSubtextClass } = useComponentClassContext();

	if (!results?.results) return <></>;

	return (
		<FlexColumnSpaced>
			<Typography style={{ ...font }}>{`Total results: ${results.total}`}</Typography>
			{results?.results?.map((result: SiteSearchResultModel) => {
				return (
					<div style={{ borderBottom: '1px solid #e9ecef' }} key={result.id}>
						<a href={`${result.urlName}`} className="link-style">
							<Typography
								variant="h4"
								style={{ ...subtextFont, ...subtextFontColour, ...headerTextShadow }}
								className={headerSubtextClass}
							>
								{result.pageHeading}
							</Typography>
						</a>
						<Typography style={{ fontStyle: 'italic' }}>
							{result.createDate ? `Created on: ${result.createDate} ` : ''}
						</Typography>
						<Typography style={{ fontStyle: 'italic' }}>
							{result.updateDate ? `Last updated on: ${result.updateDate}` : ''}
						</Typography>
					</div>
				);
			})}
		</FlexColumnSpaced>
	);
};
