import { tryParseJSONObject } from '@fox/atoms';
import React, { PropsWithChildren, createContext, useContext } from 'react';

export type StyleClasses = {
	headerClass: string;
	headerSubtextClass: string;
};

export type ComponentClassContext = StyleClasses;

const Context = createContext<StyleClasses>(undefined);
Context.displayName = 'ComponentClassContext';

export function ComponentClassContextProvider({
	children,
	classes
}: PropsWithChildren<{
	classes: string;
}>) {
	const parsedClasses = tryParseJSONObject<StyleClasses>(classes);
	const contextValue = {
		...parsedClasses
	};

	return <Context.Provider value={contextValue}>{children}</Context.Provider>;
}

export function useComponentClassContext() {
	const context = useContext(Context);

	if (!context) throw new Error('Missing class context');

	return context as ComponentClassContext;
}
