import { Button, Icon, MuiAlert, TextField, Typography } from '@fox/atoms';
import { FlexColumnSpaced, FlexRowSmallSpaced, Padded } from '@fox/layout';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { LoadingBar } from '../../common/components/LoadingBar';
import { useComponentClassContext } from '../component-class-context';
import { useComponentStyleContext } from '../component-style-context';
import { useContentContext } from '../content-context';
import { SiteSearchResultsModel } from './contracts';
import { RenderSearchResults } from './search-results';

const submitSearch = async (searchValue: string, contentId: string) => {
	const resp = await fetch(`/Umbraco/Api/SiteSearch/SearchSite?searchValue=${encodeURIComponent(searchValue)}&contentId=${contentId}`, {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json'
		}
	});
	return resp.json();
};

export const SiteSearch = () => {
	const { contentId } = useContentContext();
	const [searchValue, setSearchValue] = useState<string>('');
	const [searchResults, setSearchResults] = useState<SiteSearchResultsModel | undefined>(undefined);
	const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);
	const [error, setError] = useState<boolean>(false);
	const [showModal, setShowModal] = useState<boolean>(false);
	const { fontSizeRem, fontColour, headerFont, headerFontColour, font, fontTextShadow, headerTextShadow } = useComponentStyleContext();
	const { headerClass } = useComponentClassContext();

	const handleSubmit = async () => {
		setSubmitDisabled(true);
		const results = await submitSearch(searchValue, contentId);
		if (results?.results) {
			setSearchResults(results);
			if (error) {
				setError(false);
			}
		} else {
			setError(true);
		}
		setSubmitDisabled(false);
	};

	return (
		<>
			<div
				style={{
					width: '100%',
					height: '100%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					cursor: 'pointer'
				}}
				onClick={() => setShowModal(true)}
			>
				<Icon iconName="Search" sx={{ ...fontSizeRem, ...fontColour, ...fontTextShadow }} />
			</div>
			<Modal show={showModal} onHide={() => setShowModal(false)} centered>
				<Modal.Header closeButton>
					<Typography variant="h3" className={headerClass} style={{ ...headerTextShadow, ...headerFont, ...headerFontColour }}>
						Search
					</Typography>
				</Modal.Header>
				<FlexColumnSpaced style={{ ...font, ...fontColour, padding: '1rem' }}>
					<FlexRowSmallSpaced>
						<TextField
							onChange={e => setSearchValue(e.target.value)}
							style={{ width: '100%' }}
							placeholder="Search this site"
							onKeyDown={async e => {
								if ((e.key === 'Enter' || e.keyCode === 13) && searchValue !== '') {
									handleSubmit();
								}
							}}
						/>
						<Button
							variant="primary"
							disabled={submitDisabled}
							onClick={async () => {
								if (searchValue !== '') {
									handleSubmit();
								}
							}}
						>
							Go
						</Button>
					</FlexRowSmallSpaced>
					{submitDisabled && <LoadingBar />}
					<RenderSearchResults results={searchResults} />
					{error ? <MuiAlert severity="error">Error: Something went wrong with your search.</MuiAlert> : null}
				</FlexColumnSpaced>
			</Modal>
		</>
	);
};
