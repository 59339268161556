/** we pass in default styling via the theme, but if we want to render components through the async-rootcomponent and still have the block specific style we can now do this with this context */

import { tryParseJSONObject } from '@fox/atoms';
import React, { PropsWithChildren, createContext, useContext } from 'react';
import styled from 'styled-components';

//we need to add all other attributes here as well
export type Style = {
	textAlignment: string;
	backgroundColor: string;
	font: string;
	fontColor: string;
	fontSize: number;
	fontTextShadow: boolean;
	headerFont: string;
	headerFontColor: string;
	headerTextShadow: boolean;
	headerSubtextFont: string;
	headerSubtextFontColor: string;
	borderStyle: string;
	borderWidth: number;
	borderColour: string;
	borderRadius: number;
	boxShadowStyle: string;
	linkHoverColour: string;
	linkHoverUnderline: boolean;
};
export type ComponentStyleContextProps = {
	style: string;
};

export type ComponentStyleContext = Record<StyleMapKeys, React.CSSProperties>;
//we need to do this for all the other attributes as well
type StyleMapKeys =
	| 'textAlignment'
	| 'backgroundColour'
	| 'font'
	| 'fontColour'
	| 'fontSizeRem'
	| 'fontTextShadow'
	| 'headerFont'
	| 'headerFontColour'
	| 'headerTextShadow'
	| 'subtextFont'
	| 'subtextFontColour'
	| 'borderStyle'
	| 'borderWidth'
	| 'borderColour'
	| 'borderRadius'
	| 'boxShadowStyle'
	| 'linkHoverColour'
	| 'linkHoverUnderline';
const StyleMap: Record<StyleMapKeys, (value) => Record<string, unknown> | undefined> = {
	textAlignment: value => {
		if (value) return { textAlignment: value };
	},
	backgroundColour: value => {
		if (value) return { backgroundColor: value };
	},
	font: (value: string) => {
		if (value) {
			if (value.endsWith('-bold')) {
				return { fontFamily: value, fontWeight: 700 };
			}
			return { fontFamily: value };
		}
	},
	fontColour: value => {
		if (value) return { color: value };
	},
	fontSizeRem: value => {
		if (value) return { fontSize: `${value}rem` };
	},
	fontTextShadow: value => {
		if (value) {
			return { textShadow: 'rgba(0,0,0,.8) 3px 3px 10px' };
		}
	},
	headerFont: (value: string) => {
		if (value) {
			if (value.endsWith('-bold')) {
				return { fontFamily: value, fontWeight: 700 };
			}
			return { fontFamily: value };
		}
	},
	headerFontColour: value => {
		if (value) return { color: value };
	},
	headerTextShadow: value => {
		if (value) {
			return { textShadow: 'rgba(0,0,0,.8) 3px 3px 10px' };
		}
	},
	subtextFont: (value: string) => {
		if (value) {
			if (value.endsWith('-bold')) {
				return { fontFamily: value, fontWeight: 700 };
			}
			return { fontFamily: value };
		}
	},
	subtextFontColour: value => {
		if (value) return { color: value };
	},
	borderColour: value => {
		if (value) return { borderColor: value };
	},
	borderRadius: value => {
		if (value) return { borderRadius: `${value}px` };
	},
	borderStyle: value => {
		if (value) return { borderStyle: value };
	},
	borderWidth: value => {
		if (value) return { borderWidth: `${value}px` };
	},
	linkHoverColour: value => {
		if (value) return { color: value };
	},
	linkHoverUnderline: value => {
		if (value) return { textDecoration: 'underline' };
	},
	boxShadowStyle: value => {
		switch (value) {
			case 'faint shadow':
				return { boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px' };
			case 'half grey shadow':
				return { boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px' };
			case 'full shadow':
				return { boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px' };
			case 'solid frame 1px':
				return { boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 3px' };
			case 'full shadow 3D':
				return {
					boxShadow:
						'rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px'
				};
			case 'solid frame 0.5px':
				return {
					boxShadow:
						'rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset'
				};
			case 'rainbow frame':
				return {
					boxShadow:
						'rgb(85, 91, 255) 0px 0px 0px 3px, rgb(31, 193, 27) 0px 0px 0px 6px, rgb(255, 217, 19) 0px 0px 0px 9px, rgb(255, 156, 85) 0px 0px 0px 12px, rgb(255, 85, 85) 0px 0px 0px 15px'
				};
			case 'half blue-grey blur':
				return { boxShadow: 'rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px' };
			case 'curved shade':
				return {
					boxShadow:
						'rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px'
				};
			case 'grey solid frame 0.5px':
				return { boxShadow: 'rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px' };
			case 'none':
				return { boxShadow: 'none' };
		}
	}
};

const HoverWrapper = styled.div<{ linkHoverColour; linkHoverUnderline }>`
	.link-style:hover,
	.link-style:hover * {
		${props => (props.linkHoverColour ? `color: ${props.linkHoverColour}!important;` : '')}
		${props => (props.linkHoverUnderline ? 'text-decoration: underline!important;' : '')}
	}
`;

function generateStyle(style: Style): Record<StyleMapKeys, React.CSSProperties> {
	if (!style) return {} as Record<StyleMapKeys, React.CSSProperties>;
	return Object.entries(style)?.reduce((prev, [key, value]) => {
		const val = StyleMap[key]?.(value);
		if (val) {
			prev = { ...prev, [key]: val };
		}
		return prev;
	}, {} as Record<StyleMapKeys, React.CSSProperties>);
}

const Context = createContext<ComponentStyleContext>(undefined);
Context.displayName = 'ComponentStyleContext';

export function ComponentStyleContextProvider({
	children,
	style
}: PropsWithChildren<{
	style: string;
}>) {
	const parsedStyle = tryParseJSONObject<Style>(style);
	const styling = generateStyle(parsedStyle);
	const contextValue = {
		...styling
	};

	return (
		<Context.Provider value={contextValue}>
			<HoverWrapper linkHoverColour={parsedStyle?.linkHoverColour} linkHoverUnderline={parsedStyle?.linkHoverUnderline}>
				{children}
			</HoverWrapper>
		</Context.Provider>
	);
}

export function useComponentStyleContext() {
	const context = useContext(Context);

	if (!context) throw new Error('Missing component style context');

	return context as ComponentStyleContext;
}
