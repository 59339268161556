// async bootstrap of this component

import type { IRootThemedComponentProps } from './async-root-themed-component';

function MountRootThemedComponent(props: IRootThemedComponentProps, el: Element) {
	import('./async-root-themed-component').then(a => {
		console.log('async loaded root themed');
		a.MountRootThemedComponent(props, el);
	});
}

window.MountRootThemedComponent = MountRootThemedComponent;
