import '../../../../common/polyfills';
import React, { Component } from 'react';
import * as d3 from 'd3';
import { createRoot } from 'react-dom/client';

export class VolcanoGrapth extends Component {
	constructor(props) {
		super(props);
	}

	draw(colorOne, colorTwo) {
		const n = 20; // number of layers
		const m = 200; // number of samples per layer
		const stack = d3
			.stack()
			.keys(
				d3.range(n).map(function (d) {
					return 'layer' + d;
				})
			)
			.offset(d3.stackOffsetWiggle);

		// Create empty data structures
		const matrix0 = d3.range(m).map(function (d) {
			return { x: d };
		});
		const matrix1 = d3.range(m).map(function (d) {
			return { x: d };
		});

		// Fill them with random data
		d3.range(n).map(function (d) {
			bumpLayer(m, matrix0, d);
		});
		d3.range(n).map(function (d) {
			bumpLayer(m, matrix1, d);
		});

		let layers0 = stack(matrix0);
		let layers1 = stack(matrix1);

		const width = 3500,
			height = 500;

		const x = d3
			.scaleLinear()
			.domain([0, m - 1])
			.range([0, width]);

		const y = d3
			.scaleLinear()
			.domain([
				d3.min(layers0.concat(layers1), function (layer) {
					return d3.min(layer, function (d) {
						return d[0];
					});
				}),
				d3.max(layers0.concat(layers1), function (layer) {
					return d3.max(layer, function (d) {
						return d[1];
					});
				})
			])
			.range([height, 0]);

		let color;
		if (colorOne != null && colorTwo != null) {
			color = d3.scaleLinear().range([colorOne, colorTwo]);
		} else {
			color = d3.scaleLinear().range(['#800000', '#F08080']);
		}

		const area = d3
			.area()
			.x(function (d, i) {
				return x(d.data.x);
			})
			.y0(function (d) {
				return y(d[0]);
			})
			.y1(function (d) {
				return y(d[1]);
			});

		const svg = d3.select('.viz').append('svg').attr('width', width).attr('height', height);

		// const randomize = () => {
		//     const layers = stack(d3.transpose(Array.from({ length: n }, () => this.bumps(n, k))));
		//     y.domain([
		//         d3.min(layers, l => parseInt(d3.min(l, d => d[0]))),
		//         d3.max(layers, l => parseInt(d3.max(l, d => d[1])))
		//     ]);
		//     return layers;
		// }

		svg.selectAll('path')
			.data(layers0)
			.enter()
			.append('path')
			.attr('d', area)
			.style('fill', function () {
				return color(Math.random());
			});

		// Inspired by Lee Byron's test data generator.
		function bumpLayer(n, matrix, layer) {
			function bump(a) {
				var x = 1 / (0.1 + Math.random()),
					y = 2 * Math.random() - 0.5,
					z = 10 / (0.1 + Math.random());
				for (var i = 0; i < n; i++) {
					var w = (i / n - y) * z;
					a[i] += x * Math.exp(-w * w);
				}
			}

			const a = [];
			let i;
			for (i = 0; i < n; ++i) a[i] = 0;
			for (i = 0; i < 5; ++i) bump(a);
			return a.forEach(function (d, i) {
				matrix[i]['layer' + layer] = Math.max(0, d) + 1;
			});
		}

		const interval = (() => {
			d3.interval(() => {
				d3.selectAll('path')
					.data(function () {
						var d = layers1;
						layers1 = layers0;
						return (layers0 = d);
					})
					.transition()
					.duration(2500)
					.attr('d', area);
			}, 2500);
		})();
	}

	componentDidMount() {
		this.draw(this.props.ColorCodeOne, this.props.ColorCodeTwo);
	}

	componentDidUpdate() {
		this.draw(this.props.ColorCodeOne, this.props.ColorCodeTwo);
	}

	render() {
		return (
			<div>
				<div className="viz" />
			</div>
		);
	}
}

function MountVolcano(props, el) {
	const root = createRoot(el);
	root.render(<VolcanoGrapth {...props} />);
}

window.MountVolcano = MountVolcano;
